define("drewcovi/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S67Khmoi",
    "block": "{\"symbols\":[\"acc\",\"aitem\"],\"statements\":[[7,\"h2\",true],[8],[0,\"Hello How Are You?\"],[9],[0,\"\\n\"],[5,\"bs-accordion\",[[12,\"className\",\"bg-dark\"]],[[],[]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"item\"]],[[12,\"class\",\"bg-dark text-white\"]],[[\"@value\",\"@title\"],[\"1\",\"Title attribute\"]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[8],[0,\" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur ea eos hic neque quia sequi sunt. Amet at atque corporis earum et fugiat illum magnam nisi sapiente voluptatem. Laudantium, quam?  \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"item\"]],[[12,\"class\",\"bg-dark text-white\"]],[[\"@value\"],[\"2\"]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"title\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"strong\",true],[8],[0,\" Second \"],[9],[0,\"\\n      \"],[7,\"i\",true],[8],[0,\" item \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[6,[23,2,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"p\",true],[8],[0,\" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur ea eos hic neque quia sequi sunt. Amet at atque corporis earum et fugiat illum magnam nisi sapiente voluptatem. Laudantium, quam?  \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n  \"],[6,[23,1,[\"item\"]],[[12,\"class\",\"bg-dark text-white\"]],[[\"@value\",\"@title\"],[\"3\",\"Third item\"]],{\"statements\":[[0,\"\\n    \"],[7,\"p\",true],[8],[0,\" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur ea eos hic neque quia sequi sunt. Amet at atque corporis earum et fugiat illum magnam nisi sapiente voluptatem. Laudantium, quam?  \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "drewcovi/templates/index.hbs"
    }
  });

  _exports.default = _default;
});