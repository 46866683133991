define("drewcovi/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o79L9ZhW",
    "block": "{\"symbols\":[\"navbar\",\"nav\"],\"statements\":[[5,\"bs-navbar\",[],[[\"@type\",\"@backgroundColor\",\"@collapsed\",\"@onCollapse\",\"@onExpand\"],[\"dark\",\"dark\",true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"collapsed\"]]],null],true],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"collapsed\"]]],null],false],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"navbar-header\"],[8],[0,\"\\n    \"],[1,[23,1,[\"toggle\"]],false],[0,\"\\n    \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"navbar-brand\",\"index\"]],{\"statements\":[[0,\"Drew Covi\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n   \"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,1,[\"nav\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"link-to\"]],[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,2,[\"item\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"link-to\"]],[],[[\"@route\"],[\"work\"]],{\"statements\":[[0,\"Work\"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[7,\"main\",true],[10,\"class\",\"p-3 bg-dark text-light\"],[8],[0,\"\\n\"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "drewcovi/templates/application.hbs"
    }
  });

  _exports.default = _default;
});